

import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";

import { BASE_API_URL } from "@/config";
@Component
export default class WeLearnComponent extends Vue {
  public videoUrl: any = null;
  public videoName = null;
  public playWeLearnVideo(videoSource) {
    try {
      if (videoSource == "onBorading") {
        this.videoUrl = "https://wemlo.s3.amazonaws.com/Common/welearn/WemloOnboarding.mp4";
        this.videoName = "Easy Onboarding";
        this.$modal.show("weLearnVideo");
      }
      if(videoSource == "broker-dashboard"){
          this.videoUrl="https://wemlo.s3.amazonaws.com/Common/welearn/TheWemloDashboard.mp4";
          this.videoName="Explaining the Broker’s Dashboard";
          this.$modal.show("weLearnVideo");
      }
      if(videoSource=="new-loan"){
         this.videoUrl ="https://wemlo.s3.amazonaws.com/Common/welearn/StartANewLoan.mp4";
         this.videoName="How to Start a New Loan";
         this.$modal.show("weLearnVideo");
      }
      if(videoSource=='pos-preview'){
          this.videoUrl="https://wemlo.s3.amazonaws.com/Common/welearn/POSOverview.mp4";
          this.videoName="POS Overview";
          this.$modal.show("weLearnVideo");
      }
      if(videoSource=='pricing-tool'){
          this.videoUrl="https://wemlo.s3.amazonaws.com/Common/welearn/PricingTool.mp4";
          this.videoName="How to use the Pricing Tool";
          this.$modal.show("weLearnVideo");
      }
      if(videoSource=='los'){
          this.videoUrl="https://wemlo.s3.amazonaws.com/Common/welearn/StaraNewLoan-fnmor1003.mp4";
          this.videoName="How to Start a new 3.2 or 1003"
          this.$modal.show("weLearnVideo");
      }
      if(videoSource=='upload-documents'){
          this.videoUrl="https://wemlo.s3.amazonaws.com/Common/welearn/DocumentUploadScreen.mp4";
          this.videoName="How to Upload Documents";
          this.$modal.show("weLearnVideo");
      }
      if(videoSource=='wemlo-leads'){
          this.videoUrl="https://wemlo.s3.amazonaws.com/Common/welearn/WemloLead.mp4";
          this.videoName="Explaining the wemlo Leads";
          this.$modal.show("weLearnVideo");          
      }
      if(videoSource=='track-loan'){
          this.videoUrl="https://wemlo.s3.amazonaws.com/Common/welearn/LoansInProcessing.mp4";
          this.videoName="Tracking your loans";
          this.$modal.show("weLearnVideo");          
      }
      if(videoSource=='scenario-desk'){
          this.videoUrl="https://wemlo.s3.amazonaws.com/Common/welearn/ScenarioDesk.mp4";
          this.videoName="What is a Scenario Desk";
          this.$modal.show("weLearnVideo");          
      }
      if(videoSource=='weHelp'){
          this.videoUrl="https://wemlo.s3.amazonaws.com/Common/welearn/WeHelp.mp4";
          this.videoName="Explaining weHelp";
          this.$modal.show("weLearnVideo");          
      }
      if(videoSource=='borrower-portal'){
          this.videoUrl="https://wemlo.s3.amazonaws.com/Common/welearn/BorrowerPortal.mp4";
          this.videoName="Borrower Portal";
          this.$modal.show("weLearnVideo");          
      }
    } catch (error) {
      console.log(error);
    }
  }
}
